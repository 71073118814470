import React from 'react'
import { StaticQuery, graphql, Link } from 'gatsby'
import Image from 'gatsby-image'

import './index.scss'

export const Bio = () => (
  <StaticQuery
    query={bioQuery}
    render={data => {
      const { author, social, introduction } = data.site.siteMetadata

      return (
        <div
          className="bio"
          style={{
            padding: 10,
            backgroundColor: '#dddddd52',
            borderRadius: 10,
          }}
        >
          <div className="author">
            <div className="author-description">
              <Image
                className="author-image"
                fixed={data.avatar.childImageSharp.fixed}
                alt={author}
                style={{
                  borderRadius: `100%`,
                }}
              />
              <div className="author-name">
                <div>
                  <p>
                    👋 &nbsp; I’m Sarwech, a self-taught developer building web
                    apps and websites. Before learning to code, I was a lawyer
                    then an analyst then a growth marketer.
                  </p>
                  <p>
                    I'm building{' '}
                    <a
                      href="https://www.nocodelytics.com?utm_source=sarwech.com"
                      target="_blank"
                    >
                      Nocodelytics
                    </a>{' '}
                    (analytics for no-coders) and{' '}
                    <a
                      href="https://spacebandits.io?utm_source=sarwech.com"
                      target="_blank"
                    >
                      Space Bandits
                    </a>
                    .
                  </p>
                  <p>
                    Want to chat about your project? Send me an{' '}
                    <a href="mailto:sarwech.shar@gmail.com" target="_blank">
                      email
                    </a>{' '}
                    or find me on{' '}
                    <a href="https://www.twitter.com/sarwechs" target="_blank">
                      Twitter
                    </a>{' '}
                    :).
                  </p>
                </div>
                <p className="author-socials">
                  {social.github && (
                    <a href={`https://github.com/${social.github}`}>GitHub</a>
                  )}
                  {social.medium && (
                    <a href={`https://medium.com/${social.medium}`}>Medium</a>
                  )}
                  {social.twitter && (
                    <a href={`https://twitter.com/${social.twitter}`}>
                      Twitter
                    </a>
                  )}
                  {social.facebook && (
                    <a href={`https://www.facebook.com/${social.facebook}`}>
                      Facebook
                    </a>
                  )}
                  {social.linkedin && (
                    <a href={`https://www.linkedin.com/in/${social.linkedin}/`}>
                      LinkedIn
                    </a>
                  )}
                </p>
              </div>
            </div>
          </div>
        </div>
      )
    }}
  />
)

const bioQuery = graphql`
  query BioQuery {
    avatar: file(absolutePath: { regex: "/profile.png/" }) {
      childImageSharp {
        fixed(width: 72, height: 72) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    site {
      siteMetadata {
        author
        introduction
        social {
          twitter
          github
          medium
          facebook
          linkedin
        }
      }
    }
  }
`

export default Bio
